/* CalendarForm.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.calendar-header {
  text-align: center;
  margin-bottom: 20px;
}

.calendar-header h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.calendar-header p {
  font-size: 16px;
  color: #666;
}

.calendar-form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 400px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.calendar-form:hover {
  transform: translateY(-5px);
}

.calendar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.calendar-label {
  font-size: 16px;
  color: #333;
  flex: 1;
}

.calendar-checkbox {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.calendar-input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.calendar-submit {
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.calendar-submit:hover {
  background-color: #cc0000;
}
