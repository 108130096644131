/* Container styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure the container takes full width */
  height: 100vh; /* Ensure the container takes full viewport height */
  padding: 20px;
  box-sizing: border-box; /* Include padding in width and height */
  overflow: hidden; /* Prevent scrolling */
}

/* Content wrapper styles */
.content-wrapper {
  display: flex;
  flex-direction: row; /* Set direction to row for side-by-side sections */
  width: 100%;
  max-width: 1200px; /* Set a maximum width for the content */
  background-color: #ffffff; /* White background for the form */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding in width */
}

/* Image section styles */
.image-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px; /* Add padding */
}

.image-section img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  width: 350px; /* Set a fixed width */
}

.image-section p img{
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  width: 150px; /* Set a fixed width */
  margin-Left: '10px'; 
  vertical-Align: right;
}
/* Form section styles */
.form-section {
  flex: 1;
  padding: 20px;
  background-color: #ff0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch; /* Ensures the form takes full width */
}

.form-section h1 {
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff; /* Ensures the heading color contrasts with background */
}

/* Form styles */
.form {
  display: grid;
  gap: 10px;
}

.form div {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
}

.form label {
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
  color: #ffffff; /* Ensures the label color contrasts with background */
}

.form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  box-sizing: border-box;
}

.form input:focus {
  border-color: #007bff; /* Change border color on focus */
}

/* Button styles */
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: black;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button-container button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    height: auto;
    padding: 20px;
  }

  .content-wrapper {
    flex-direction: column;
    padding: 10px;
  }

  .image-section,
  .form-section {
    flex: none;
    width: 100%;
    padding: 10px;
  }

  .button-container {
    justify-content: center;
  }
}
