/* Container styles */
.ticket-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ticket-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  /* Removed fixed width, use full width */
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px; /* Consider reducing for phones */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .ticket-content-wrapper {
    padding: 10px 20px;  /* Reduced padding for phones */
  }
  
  .ticket {
    padding: 20px; /* Adjust padding if needed */
  }
}

.ticket img {
  width: 150px; /* Adjust width as needed */
  height: auto;
}


/* Ticket styles */
.ticket {
  width: 100%;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url('./ticket.jpg'); /* Background image for the ticket */
  background-size: cover;
  background-position: center;
  color: red;
  text-align: center;
  border: grey, solid;
}



.ticket-header h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.ticket-body {
  font-size: 1rem; /* Adjust font size as needed */
  margin-bottom: 10px;
}

.ticket-body p {
  font-size: 18px;
  margin-bottom: 10px;
  /* text-align: left; */
}
.ticket-footer {
  /* background-color: red; */
  margin-top: 20px;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap; /* Added property */
  margin-top: 10px;
}

.qr-code p {
  text-align: center;
  /* background: #eae91c;
  border-radius: 10px; */
}
/* Button styles */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container button {
  padding: 12px 24px; /* Increased padding for better clickability */
  font-size: 16px;
  background-color: #0056b3; /* Darker shade of blue */
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button-container button:hover {
  background-color: #004999; /* Darker shade on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .ticket-content-wrapper {
    width: 90%;
    padding: 20px;
  }
  
  
  .ticket {
    padding: 30px;
  }
}
.icons {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding-top: 5px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  /* background-color: #f00; */
  border: solid 2px black;
  border-radius: 5px;
}

.icon span {
  margin-top: 10px;
}