/* ContactList.css */
.contact-list-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #ff0000;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.contact-list {
  list-style-type: none;
  padding: 0;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.contact-item:hover {
  background-color: #fafafa;
}

.contact-info {
  text-align: center;
  margin-bottom: 10px;
}

.contact-email {
  font-size: 18px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 5px;
}

.contact-name {
  color: #555;
  font-size: 16px;
  margin-bottom: 10px;
}

.qr-code {
  margin-bottom: 15px;
}

.contact-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.page-link:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .contact-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contact-info {
    text-align: left;
  }

  .qr-code {
    margin-left: 20px;
    margin-bottom: 0;
  }
}
.valid-message {
  font-size: 1.5em;
  margin-top: 10px;
  text-align: center;
}

.valid-message.valid {
  color: green;
}

.valid-message.invalid {
  color: red;
}
@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
